#DianXin{
    width: 100%;
}
#DianXin .Cp_top_banner div{
    width: 30%;
    top: 75%;
    left: 75%;
}
#DianXin .Cp_top_banner h3{
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(220,55,48,1);
}
#DianXin .Cp_top_banner p{

    font-size:2.25rem;
    font-weight:400;
    color:rgba(74,76,84,1);
}

