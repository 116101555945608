#Branch{
    width:100%;
    background:rgba(255,255,255,1);
}
.ditu{
    width: 100%;
    height: 680px;
    margin-top: 95px;
    position: relative;
    overflow: hidden;
    background-position: top left;
    background-size: 100% 100%;
}
.ditu p{
    position: absolute;
    bottom: 55px;
    left: 160px;
    font-size:2.5rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.ditu span{
    position: absolute;
    font-size:14px;
    font-weight:bold;
    text-indent: 5px;
    color:rgba(102,102,102,1);
}
.ditu span::after{
    content: '';
    box-sizing: content-box;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 3px;
    left: -20px;
    background:#DB3131;
    border-radius:50%;
    box-shadow: #FFFFFF 0px 0px 0px 1px;
    border: 4px solid #FFFFFF;
    animation: dituB 0.5s ease-in-out infinite alternate;
}

@keyframes dituB
{
    from {box-shadow: #FFFFFF 0px 0px 0px 1px;}
    to {
        box-shadow: #FFFFFF 0px 0px 0px 4px;
    }
}

.Branch_bot_div {
    width: 100%;
    height: 5rem;
    background:rgba(238,241,247,1);
}
/*美国*/
.U_S_A{
    /*top: 257px;
    left: 517px;*/
    top:37.79%;
    left: 26.92%;
}
/*拉丁美洲*/
.LDMZ{
    top: 63%;
    left: 34.5%;
}
/*西班牙*/
.Spain{
    /*top: 265px;
    left: 903px;*/
    top:38.97%;
    left:47.5%;
}
/*丹麦*/
.Denmark{
    top: 28.67%;
    left: 50.5%;
}
/*斯洛文尼亚*/
.Slovenia{
    /*top: 240px;
    left: 975px;*/
    top:35.29%;
    left:51.2%;
}
/*土耳其*/
.Turkey{
    top: 39.7%;
    left: 54.4%;
}
/*印度*/
.India{
    top: 49.55%;
    left: 63%;
}
/*深圳*/
.Shenzhen{
    top: 49.85%;
    left: 70.2%;
}
/*山东*/
.Shandong{
    top: 41.47%;
    left: 70.6%;
}
/*菲律宾*/
.Philippines{
    top: 54.11%;
    left: 71.2%;
}
/*韩国*/
.Korea{
    top: 38.67%;
    left: 72.47%;
}
/*日本*/
.Japan{
    top: 41.76%;
    left: 74.82%;
}

.Branch_list{
    width: 80%;
    margin: 0 auto;
    padding: 40px 0 30px;
}
.Branch_list ul{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Branch_list li{
    display: flex;
    width: 50%;
    position: relative;
    flex-wrap: wrap;
    align-items: end;
}
.Branch_list li::after{
    content: '';
    width: 2px;
    height: 50%;
    position: absolute;
    background:rgba(102,102,102,0.2);
    border-radius:1px;
    top: 50%;
    left: -2.5rem;
    transform: translateY(-50%);
}
.Branch_list li:hover::after{
    background:rgba(220,55,48,1);
}
.Branch_list li:nth-child(2n+1){
    padding:0px 35px 50px 0;
}
.Branch_list li:nth-child(2n){
    padding:0px 0px 50px 35px;
}
.Branch_list li:nth-child(2n)::after{
    left: -1rem;
}
.Branch_list li div{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.Branch_list h3{
    margin-bottom: 1rem;
    font-size:1.5rem;
    font-weight:bold;
    color:rgba(220,55,48,1);
}
.Branch_list p{
    margin-right: 2rem;
    line-height: 2rem;
    font-size:1.2rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.w_100{
    width: 100%;
    margin: 0!important;
}
@media screen and (max-width: 1610px){
    /*.LDMZ{
        left: 41%;
    }*/
}