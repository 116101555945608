#Honor{
    width: 100%;
    height: 100%;
    background:rgba(255,255,255,1);
}

.Hon_content{
    height: 70%;
    background:rgba(238,241,247,1);
}
.Hon_content h3{
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: Center;
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(118,118,118,1);
}
.Hon_content ul{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin:  0 auto;
    overflow: hidden;
}
.Hon_content ul li{
    float: left;
    margin: 1rem 1.1rem;
}
.Hon_bot{
    width: 100%;
    height: 25%;
}
.Hon_bot ul{
    width: 80%;
    display: flex;
    justify-content: center;
    margin:  0 auto;
    overflow: hidden;
    position: relative;
    top: -50%;
    left: 0;
}
.Hon_bot ul li{
    float: left;
    width: 20%;
    margin: 0 1rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.Hon_bot ul li img{
    width: 100%;
}
.Hon_bot ul li h3{
    font-size:1.5rem;
    padding: 1rem 0;
}
.Hon_bot ul li p{
    font-size:1.1rem;
}

@media (max-width: 1610px){
    .Hon_content ul{
        width: 60%!important;
    }
    .j_b_content h3{
        font-size: 2rem;
    }
}
@media (max-width: 1250px){
    .Hon_content ul{
        width: 80%!important;
    }
}

