/*APP3--section_2*/
.S2_Y{
    width: 100%;
    height: 100%;
}
.S2_top{
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
}
.S2_top p{
    width: 80%;
}
.S2_list{
    width: 100%;
    height: 50%;
    margin-top: 4rem;
    margin-bottom: 5rem;
    background:rgba(255,255,255,1);
}
.S2_list ul{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.S2_list ul li{
    float: left;
    border-left: 1px solid rgba(238,241,247,1);
    padding: 1rem 2rem;
    color:rgba(102,102,102,1);
    width: 16.5%;
    min-height: 22rem;
}
.S2_list ul li:last-child{
    border-right: 1px solid rgba(238,241,247,1);
}
.S2_list_Ld{
    width: 100%;
    height: 100%;
    padding: 4rem 0 2rem;
    text-align: center;
    position: relative;
}
.S2_list_Ld::after{
    content: ' ';
    position: absolute;
    bottom: 5%;
    left: 30%;
    width: 50%;
    height: 4px;
    background: rgba(238,241,247,1);
    border-radius:2px;
}
.S2_li_img{
    width: 6.25rem;
    height: 6.25rem;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position:center center;
    background-size: 40%;
    border: 1px solid rgba(220,55,48,1);
    border-radius: 50%;
}
.S2_list_Ld h3{
    font-size:1.5rem;
    font-weight:400;
    color:rgba(220,55,48,1);
    line-height:4.5rem;
}
.S2_list_Ld p{
    font-size:1.1rem;
    font-weight:400;
}

.S2_list ul li:hover .S2_li_img{
    background-size: 40%;
    border: 1px solid white;
    border-radius: 50%;
}

.S2_list ul li:hover,
.S2_list ul li:hover .S2_list_Ld h3,
.S2_list ul li:hover .S2_list_Ld p{
    background: rgba(220,55,48,1);
    color:rgba(255,255,255,1);
}

.S2_list ul li:hover .S2_list_Ld::after
{
    background: rgba(255,255,255,1);
}
.S2_T1{
    background-image: url("../images/img3/AnQuan-red.png");
}
.S2_list ul li:hover .S2_T1{
    background-image: url("../images/img3/AnQuan-bai.png") ;
}
.S2_T2{
    background-image: url("../images/img3/LaiYong-red.png") ;
}
.S2_list ul li:hover .S2_T2{
    background-image: url("../images/img3/LaiYong-bai.png") ;
}
.S2_T3{
    background-image: url("../images/img3/ZhuanY-red.png") ;
}
.S2_list ul li:hover .S2_T3{
    background-image: url("../images/img3/ZhuanY-bai.png") ;
}
.S2_T4{
    background-image: url("../images/img3/JNHB-red.png") ;
}
.S2_list ul li:hover .S2_T4{
    background-image: url("../images/img3/JNHB-bai.png") ;
}
.S2_T5{
    background-image: url("../images/img3/QingLH-red.png") ;
}
.S2_list ul li:hover .S2_T5{
    background-image: url("../images/img3/QiangLH-bai.png") ;
}
.S2_T6{
    background-image: url("../images/img3/GaoNN-red.png") ;
}
.S2_list ul li:hover .S2_T6{
    background-image: url("../images/img3/GaoNN-bai.png") ;

}
