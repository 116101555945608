#chuang{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}
#chuang .cimg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /*background: url("../img/Ybanner2.png") no-repeat center center;*/

    background-size: cover;
}
#chuang .cu{
    position: absolute;
    top: 20vh;
    left: 0%;
    opacity: 0;
    transition: all 1s ;
}
#chuang .cp{
    width: 100vw;
    position: absolute;
    background: rgba(255,255,255,1);
    color: rgba(76,76,76,1);
    height: 20vh;
    bottom: 0vh;
    left: 0vw;
}
#chuang .cp p{
    width: 70%;
    margin: 15rem auto;
    opacity: 0.1;
    line-height: 2rem;
    text-align: left;
    font-size: 1.2rem;
    transition: all 1s;
}
#chuang .cu h3{
    padding-left: 2rem;
    font-size: 3rem;
    line-height: 4rem;
    display: inline-block;
    font-weight:bold;
    color:rgba(255,255,255,1);
    position: relative;
}

#chuang .cu h3::before{
    width: 0.4rem;
    height: 100%;
    display: block;
    position: absolute;
    left: 0rem;
    bottom: 0rem;
    background: red;
    content:' ';
}
#chuang .cu p{
    width: 50%;
    font-size: 1.25rem;
    line-height: 2.5rem;
    margin-top: 2rem;
    text-align: left;
    font-weight:400;
    color:rgba(255,255,255,1);

}
