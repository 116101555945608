.S4_Chi_content{
    background: rgba(238,241,247,1);
    overflow: hidden;
}
.S4_Chi_Curtain{
    background: url("../images/img3/Chi-banner2.jpg") no-repeat center center;
}
.S4_Chi_Curtain>div{
    text-align: left;
    top: 60%;
    left: 50%;
}
.S4_Chi_Curtain>div p{
    margin: 0;
    width: 70%;
    font-size: 2.5rem;
}
.S4_Chi_bot{
    background: rgba(238,241,247,1);
}
.S4_Chi_bot li{
    padding-top: 7rem;
    background: rgba(255,255,255,1);
    min-height: 33rem;
}
.S4_Chi_bot li p{
    padding: 0 2rem;
}