/*App6_XW——XW_select.js*/
.XW_select{
    background:rgba(238,241,247,1);
}
.XW_select .Cp_Select ul{
    display: flex;
    justify-content: center;
}
.XW_select .Cp_Select ul li{
    width: 35%;
    color:rgba(102,102,102,1);
}

.XW_select_list{
    width: 83%;
    margin: 0 auto;
    padding-bottom: 2rem ;
}

.XW_select_list li{
    width: 100%;
    padding: 1.8rem 1.8rem;
    margin: 2.1rem 0;
    background:rgba(255,255,255,1);
    display: flex;
    justify-content: space-between;
}
.XW_select_list li:hover{
    box-shadow: 0 15px 10px -10px rgba(102,102,102,0.2);
}
.XW_select_list li:hover .XW_select_list_content h3,
.XW_select_list li:hover .XW_select_list_date a,
.XW_select_list li:hover .XW_select_list_date span{
    color:rgba(220,55,48,1);
}
.XW_select_list_img{
    flex: 2;
    display: flex;
    align-items: center;
}
.XW_select_list_img img{
    width: 360px;
    height: 240px;
}
.XW_select_list_content{
    flex: 4;
    padding: 3%;
}
.XW_select_list_content h3{
    margin-bottom: 2.3rem;
    font-size:2.25rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.XW_select_list_content p{
    font-size:1.1rem;
    line-height: 2rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}

.XW_select .S4_router{
    position: relative;
}

.XW_select_list_date{
    flex: 1;
    padding: 2rem;
    text-align: center;
    position: relative;
}
.XW_select_list_date::after{
    content: '';
    width:1px;
    height:240px;
    background:rgba(153,153,153,0.2);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.XW_select_list_date h1{
    width: 100%;
    font-size:4rem;
    font-weight:bold;
    color:rgba(210,210,210,1);
}
.XW_select_list_date p{
    width: 100%;
    font-size:1.2rem;
    font-weight:400;
    color:rgba(210,210,210,1);
}
.XW_select_list_date a,.XW_select_list_date span{
    font-size:1.1rem;
    font-weight:400;
    color:rgba(210,210,210,1);
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
}


/*点击列*/
.XW_select_but{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 55px;
    display: flex;
    justify-content: center;
}
.XW_select_but li{
    width:40px;
    height:40px;
    text-align: center;
    line-height: 40px;
    font-size:1.1rem;
    font-weight:400;
    color:rgba(153,153,153,1);
    border:1px solid rgba(210,210,210,1);
    margin: 0 0.25rem;
    cursor: pointer;
}


.XW_select_but>li:last-child img{
    display: inline-block;
    margin-left: 4px;
    margin-top: -3px;
}
.XW_select_but>li:first-child img{
    display: inline-block;
    margin-left: -2px;
    margin-top: -3px;
}
.XW_arrows{
    display: inline-block;
}
.XW_arrows_b{
    display: none!important;
}
.XW_select_but li:hover .XW_arrows{
    display: none!important;
}
.XW_select_but li:hover .XW_arrows_b{
    display: inline-block!important;
}
/*.XW_select_but li:last-child{
    border-right: 1px solid rgba(210,210,210,1);
}*/
.XW_select_but li:hover{
    background: rgba(220,55,48,1);
    color: rgba(255,255,255,1);
}
