#DianDongGJ .Cp_top_banner h3{
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(255,255,255,1);
}
#DianDongGJ .Cp_top_banner p{
    font-size:2.25rem;
    font-weight:400;
    color:rgba(255,255,255,1);
}
#DianDongGJ .S4_Li_Curtain>div{
    width: 100%;
    background:url("../img4/Cp_DianDongGJ/bg2.png") no-repeat center center;
    padding: 2rem 0;
}
#DianDongGJ .Cp_Select_modular_left>div{
    color:rgba(255,255,255,1);
}
#DianDongGJ .Cp_Select_modular_left>div ul li::after{
    background:rgba(255,255,255,1);
}