#DianDongC .S4_Li_Curtain>div{
    text-align: left;
    width: 50%;
    top:25%;
    left: 10%;
    transform: translate(0%,0%);
}

#DianDongC .S4_Li_Curtain>div h3{
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(255,255,255,1);
}

#DianDongC .S4_Li_Curtain>div p{
    font-size:1.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
}

#DianDongC .Cp_Select_modular_left span{
    font-size:2.5rem;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:2rem;
}

#DianDongC .Cp_Select_modular_right_bottom h3{
    line-height: 3rem;
}

#DianDongC .bai,#DianDongC .bai span{
    color:rgba(255,255,255,1);
    /*color:rgba(102,102,102,1)!important;*/
}
#DianDongC .bai ul li::after{
    /*background:rgba(102,102,102,1)!important;*/
    background:rgba(255,255,255,1);
}
