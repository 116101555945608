#Exp{
    width: 100%;
    height: 100%;
}

.Exp_top{
    /*background: url("../img2/bg2.png") no-repeat center center;*/
    background-size: cover;

}
.Exp_bot{
    /*background: url("../img2/bg3.png") no-repeat center center;*/
    background-size: cover;
}


.Exp_t_content{
    width: 80%;
    height: 100%;
    margin: 0 auto;
}

.Exp_box .Exp_t_title{
    height: 30%;
    text-align: center;
    display: flex;
    justify-content:center;
    align-items:Center;
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(118,118,118,1);
}

.Exp_bot p{
    width: 30%;
    margin: 0 auto;
    padding-top: 6rem;
    text-align: center;
    font-size:1.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    position: relative;
}
.Exp_bot p::after{
    content: '';
    position: absolute;
    width: 6.875rem;
    height: 10px;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    background:rgba(255,255,255,1);
}

.Exp_turn{
    width: 100%;
    height: 70%;
}
.Exp_turn_content{
    height: 70%;
    overflow: hidden;
    position: relative;
}
.Exp_turn_content::after{
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 23.6%;
    left: 0;
    background:rgba(220,55,48,0.5);;
}
.Exp_turn_content ul{
    width: 200%;
    margin-left: 0;
    display: inline-flex;
    overflow: hidden;
    transition: all 0.1s;
}
.Exp_turn_content ul li{
    width: 25%;
}
.Exp_turn_content ul li h2{
    font-size:3.1rem;
    font-weight:bold;
    color:rgba(220,55,48,1);
    line-height: 5rem;
}
.Exp_turn_content ul li span{
    display: block;
    width: 8rem;
    height: 0.5rem;
    background:rgba(220,55,48,1);
}
.Exp_turn_content ul li h3{
    font-size:1.5rem;
    padding: 1.87rem 0 0.9rem;
    font-weight:400;
    line-height: 1.5rem;
    color:rgba(102,102,102,1);
}
.Exp_turn_content ul li>div{
    width: 80%;
    margin: 0 auto;
}
.Exp_turn_content ul li p{
    font-size: 1rem;
    margin-left: 1.5rem;
    line-height:1.5rem;
    font-weight:400;
    color:rgba(152,152,152,1);
    position: relative;
}
.Exp_turn_content ul li p::after{
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 0.7rem;
    left: -8%;
    background: rgba(220,55,48,1);
    border-radius:50%;
}

.Exp_turn_arrows{
    height: 20%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.E_prev,.E_next{
    width: 14rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.Exp_turn_arrows a{
    width: 3.5rem;
    height: 3.5rem;
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
}
.Exp_turn_arrows a img{
    line-height: 3.5rem;
}
.E_next a img{
    margin-right: -5px;
}
.arrows{
    display: block;
}
.arrows_b{
    display: none;
}
.Exp_turn_arrows a:hover{
    background:rgba(153,153,153,0.5);
}
.Exp_turn_arrows a:hover .arrows{
    display: none;
}
.Exp_turn_arrows a:hover .arrows_b{
    display: block;
}