* { margin:0; padding:0;}
li{list-style: none}
#root,#root > div:nth-child(1),.App,.App-header{
  width: 100%;
  height: 100%;
}
a:hover {text-decoration: none;}

body{color:#222;-webkit-text-size-adjust:none;}
body,h1,h2,h3,h4,h5,h6,hr,p,blockquote,dl, dt,dd,ul,ol,li,pre,form,fieldset,legend,button,input,textarea,th,td,iframe{margin:0; padding:0;}

ol,ul{list-style:none;}

html,body,xmp{ width:100%; height:100%; font-family: 微软雅黑}

.display_none{
    display: none;
}
.display_block{
    display: block;
}

.flex_list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.logo1{
    width: 9.2rem;
  display: block;
  position: absolute;
  top: 3rem;
  left: 3.75rem;
  z-index: 100;
}

.sou{
  color: white;
  display: block;
  position: absolute;
  top: 3rem;
  right: 3.75rem;
  z-index: 100;
}
.sou_two{
    display: none;
}
#scrollBar .serch{
    padding-top: 1.1rem;
    padding-right: 1.1rem;
    position: relative;
    top: 0.1rem;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}
 .serch{
     padding-top: 1.1rem;
     padding-right: 1.1rem;
     position: relative;
     top: 0.1rem;
     display: inline-block;
     width: 1rem;
     height: 1rem;
  background: url("images/img/serch.png") no-repeat center center;
}
.serch:focus + .form input{
  animation: maxWidth 1s forwards;
}

 .sou span{
    color: white;
    font-size: 1.1rem;
    margin: 0 0.2rem;
}
 .sou a{
    font-size: 1.1rem;
    list-style: none;
    color: white;
}

 .form{
  display: inline-block;
  border-bottom: 1px solid rgba(255,255,255,1);
}

.form input{
  font-size: 1rem;
  color: white;
  border: 0;
  outline:none;
  text-indent:0.5rem;
  background: rgba(248, 244, 255, 0);
  width: 0px;
}
.serch:focus + .form input{
  animation: maxWidth 1s forwards;
}
 .form input:focus{
  width: 10rem
}
 .form input::-webkit-input-placeholder{
  color: white;
}


.lazy_bg{
  background-repeat: no-repeat;
  background-position:center center;
    background-size: cover;
}
@media (max-width: 1024px){
    html{
        font-size: 12px;
    }
}
@media (max-width: 1210px){
    html{
        font-size: 12px;
    }
}
@media (max-width: 1450px){
    #Section_1{
        padding-bottom: 0px!important;
    }
    .Cp_Select_modular_right_bottom>div{
        padding: 1rem!important;
    }
}
@media (max-width: 1610px){
  html{
    font-size: 14px;
  }
    .Exp_turn_content::after{
        top: 20.6%!important;
    }
}
@media (min-width: 2000px){
    html{
        font-size: 26px;
    }
    .J_L p,.J_R p{
        padding: 0 10%;
    }
    .J_L h3{
        left: 10%!important;
    }
    .Exp_turn_content::after{
        top: 20.6%!important;
    }
    .container {
        max-width: 80%;
    }
}
@media (min-width: 3500px){
    html{
        font-size: 34px;
    }
}

@media (max-height: 800px){
    html{
        font-size: 12px;
    }
}
