.App3{
    width: 100%;
    height: 100%;
}
.App3 p{
    font-size:1.5rem;
    font-weight:400;
    color:rgba(102,102,102,1);
}
#Section_1,#Section_2{
    width: 100%;
    background: rgba(238,241,247,1);
}
#Section_1{
    height: 85%;
    padding-bottom: 5rem;
}
#Section_2{
    min-height: 80%;
}

.S1_top{
    /*height: 30%;*/
}
.S1_top h3{
    font-size:2.5rem;
    line-height: 2.5rem;
    padding: 4rem 0 2rem;
    font-weight:bold;
    color:rgba(220,55,48,1);
    text-align: center;
}
.S1_top p{
    width: 70%;
    text-align: center;
    margin: 0 auto;
}
