/*App6_XW——banner.js*/
.App6{
    background: #EEF1F7;
}
.XW_banner {
    position: relative;
    height: 100vh;
}
.XW_banner .Banner_XW_title{
    position: absolute;
    text-align: left;
    top: 60%;
    transform: translateY(-50%);
    height: auto;
    padding: 0;
    left: 18%;
}
.XW_banner .Banner_XW_title::after{
    content: '';
    width: 8rem;
    height: 4px;
    background: rgba(220,55,48,1);
    position: absolute;
    bottom: -1.5rem;
    left: 0;
}
.Banner_news{
    height: 600px;
    background-size: auto;
    background-position: top left;
    background-image: url("../../images/img6_XW/banner_1.png");
}