#S4_Ji{
    width: 100%;
    height: 100%;
    background:rgba(238,241,247,1)
}
.S4_Ji_Curtain{
    width: 100%;
    height: 800px;
    background: url("../images/img3/Jibanner2.png") no-repeat center center;
    background-size: cover;
    position: relative;
}
.S4_Ji_Curtain>div{
    width: 80%;
    margin: auto;
    text-aJign: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.S4_Ji_Curtain>div h3{
    padding: 2rem 0;
    font-size:3.75rem;
    font-weight:bold;
    color:rgba(255,255,255,1);
    text-shadow:0px 3px 5px rgba(43,43,43,0.15);
}
.S4_Ji_Curtain>div p{
    width: 60%;
    font-size:2.5rem;
    font-weight:400;
    color:rgba(255,255,255,1);
}

.S4_Ji_box{
    width: 80%;
    margin: 0 auto;
}
.S4_Ji_top{
    width: 100%;

}
.S4_Ji_top_title{
    width: 100%;
    padding-top: 2rem;
    display: inline-block;
    text-align: left;
}
.S4_Ji_top_title h3{
    text-align: left;
    font-size: 2.5rem;
}
.S4_Ji_top p{
    text-align: left;
    font-size: 1.5rem;
    width: 100%;
}

.S4_Ji_bot {
    width: 100%;
    padding: 4rem 0 7rem;
}
.S4_Ji_bot li{
    width: 23%;
    /*height: 25rem;*/
    float: left;
    text-align: center;
    position: relative;
    overflow: hidden;
    background:rgba(255,255,255,1);
}
.S4_Ji_bot li img{
    width: 100%;
    height: 100%;
    transition: all 1s;
}
.S4_Ji_img{
    width: 100%;
    /*height: 70%;*/
    overflow: hidden;
    background-repeat: no-repeat;
    background-position:center center;
    background-size: cover;
}
.S4_Ji_TU1{
    background-image: url("../images/img3/Ji-01.jpg");
}
.S4_Ji_TU2{
    background-image: url("../images/img3/Ji-02.jpg");
}
.S4_Ji_TU3{
    background-image: url("../images/img3/Ji-03.jpg");
}
.S4_Ji_TU4{
    /*background-image: url("../images/img3/Ji-04.jpg");*/
}
.S4_Ji_bot li p{
    font-size:1.5rem;
    font-weight:400;
    color:rgba(102,102,102,1);
    padding: 1.8rem 0;
}
.S4_Ji_bot li::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0rem;
    background: rgba(221,61,54,1);
    /*transition: all 1s;*/
}
.S4_Ji_bot li:hover ,
.S4_Ji_bot li:hover p,
.S4_Ji_bot li:hover::after{
    color:rgba(221,61,54,1);
}
.S4_Ji_bot li:hover::after{
    width: 100%;
}
.S4_Ji_bot li:hover img{
    transform: scale(1.2);
}

