#Jian{
    width: 100%;
    height: 100%;
    background:rgba(238,241,247,1);
}
#Jian .row>div:first-child{
    padding: 0;
}
.j_box{
    width: 100%;
    height: 100%;
}

#Jian .j_box .j_top{
    width: 100%;
    height: 75%;
}
.j_bot{
    display: flex;
    align-items: center;
    background-size: cover;
}

.J_top_img{
    height: 50%;
    text-align: center;
}
.J_top_img img{
    margin-top: 4rem;
}
.J_top_content{
    height: 50%;
    font-size:1rem;
    font-weight:400;
    color:rgba(118,118,118,1);
}
.J_L{
    position: relative;
}
.J_L h3{
    position: absolute;
    top: -3rem;
    left: 7.5%;
    font-size:1.5rem;
    font-weight:bold;
    color:rgba(118,118,118,1);
}
.J_L p,.J_R p{
    width: 85%;
    margin: 0 auto;
    line-height: 1.8rem;
    font-size:1.1rem;
    font-weight:400;
    color:rgba(118,118,118,1);
}
.j_b_content{
    width: 50%;
    margin: 0 auto;
    font-size:1.1rem;
    font-weight:400;
    color:rgba(255,255,255,1);
    text-align: center;
}
.j_b_content h3{
    padding: 0rem 0 2rem;
    font-size:2.5rem;
}

@media (min-width: 1200px){
    .container {
        max-width: 80%!important;
    }
    #Section_4 .container {
        max-width: 100%!important;
    }
}
